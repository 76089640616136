//## For each of Bootstrap's buttons, define text, background and border color.

$body-text-color: #575962;
$white-color: #ffffff;
$grey-color: #f1f1f1;
$grey2-color: #f9fbfd;
$black-color: #191919;
$transparent-bg: transparent;
$dark-color: #1a2035;
$dark2-color: #1f283e;
$primary-color: #a023a3;
$primary2-color: #8a398c;
$secondary-color: #1572e8;
$secondary2-color: #1269db;
$info-color: #48abf7;
$info2-color: #3697e1;
$success-color: #31ce36;
$success2-color: #2bb930;
$warning-color: #ffad46;
$warning2-color: #ff9e27;
$danger-color: #f25961;
$danger2-color: #ea4d56;

// Font Weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-extrabold: 700;
