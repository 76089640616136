@import "./bootstrap/bootstrap.scss";
@import "./atlantis.scss";

@import "../fonts/gilroy/stylesheet.css";
@import "../fonts/helvetica/stylesheet.css";

body {
  font-family: "Gilroy", sans-serif !important;
}

* {
  font-family: "Gilroy", sans-serif !important;
}

.special-select {
  min-height: 40px;
  border: 1px solid #ebedf2;
  font-size: 16px;
  border-radius: 0.25em;
  padding: 0 11px;
  width: 100%;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: transparent
    url("data:image/svg+xml;utf8,<svg fill='rgba(0, 0, 0, 0.25)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  transition: all 0.3s, height 0s;

  &:focus,
  &:hover {
    border-color: #b041ae;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(160, 35, 163, 0.2) !important;
  }
}

.scrollbar-inner {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-row {
  margin-bottom: 15px;
  .ant-form-item-label {
    padding: 0px !important;
    label {
      height: 30px !important;
    }
  }
}

.ant-btn.btn {
  padding: 0 20px !important;
  font-weight: 500;
}
.ant-popover-message-title {
  padding-left: 0 !important;
}
.ant-btn.btn-icon {
  padding: 0 0 !important;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-grey {
  background: $grey-color !important;
  border-color: $grey-color !important;
  color: $dark-color;
  &:hover,
  &:focus,
  &:disabled {
    background: $grey-color !important;
    border-color: $grey-color !important;
    color: $dark-color;
  }
}

.app-tag {
  border: none;
}

// Softs
.bg-soft-primary {
  background-color: lighten($primary-color, 42%) !important;
}
.bg-soft-danger {
  background-color: lighten($danger-color, 30%) !important;
}
.bg-soft-success {
  background-color: lighten($success-color, 42%) !important;
}
.bg-soft-secondary {
  background-color: lighten($secondary-color, 42%) !important;
}
.bg-soft-warning {
  background-color: lighten($warning-color, 32%) !important;
}
.bg-soft-info {
  background-color: lighten($info-color, 30%) !important;
}
.bg-soft-muted {
  background-color: lighten($grey-color, 2%) !important;
}

.app-image-picker {
  position: relative;
  display: inline-block;
  background-color: $grey-color;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  &.app-image-picker-circle {
    border-radius: 100%;
    label {
      border-radius: 100%;
    }
  }
  label {
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: block;
    background-color: darken($grey-color, 2%);
    cursor: pointer;
    &:hover {
      background-color: darken($grey-color, 3%);
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  .app-image-default {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  button.app-image-picker-clear {
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -18px;
    width: 36px;
    height: 26px;
    background-color: $grey-color;
    border: none;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 100% 100%;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      color: $danger-color;
    }
  }

  &.app-image-picker-multiple {
    width: 100%;
    label {
      overflow: visible;
      white-space: normal;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      display: block;
      background-color: darken($grey-color, 2%);
      cursor: pointer;
      &:hover {
        background-color: darken($grey-color, 3%);
      }
      .app-image-default {
        height: 240px;
      }
    }
    img {
      width: 100%;
      border-radius: 10px;
      height: 100%;
    }
  }
}

.ql-editor {
  min-height: 150px !important;
}
